/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import addToCartAction from '@store/redux/actions/addToCart'
import { RootState } from '@store/redux/reducers'
import { CustomAttribute, CartLineItem } from 'types/checkout'
import { CartState } from '@store/redux/reducers/cartReducer'
import useABtests from '@helpers/hooks/useABtests'
import { getAbTestCheckoutAttributes } from '@helpers/cart'
import { getCartUpdatePayload } from '../helpers'
import { sortCustomAttributes } from '@helpers/cart/customAttributes'
import updateCart from '@store/redux/actions/updateCart'
import updateCartAttributes from '@store/redux/actions/updateCartAttributes'
import { SubmitButtonHandlers } from '@components/ui/Button/SubmitButton'

export type ProductAddToCart = {
  quantity: number
  merchandiseId: string
  attributes: CustomAttribute[]
}

export type AddToCart = (
  products: ProductAddToCart[],
  context?: string
) => Promise<void>

export type UpdateCartQuantity = (
  id: string,
  variantId: string,
  quantity: number,
  context?: string
) => Promise<void>

export type UpdateGiftMessage = (message: string) => Promise<void>

type UseCartReturn = {
  cart: CartState
  lines: CartLineItem[]
  isLoadingCartItems: boolean
  updateCartQuantity: UpdateCartQuantity
  addToCart: AddToCart
  updateGiftMessage: UpdateGiftMessage
  navigateToCheckout: ({ triggerButtonReady }: SubmitButtonHandlers) => void
}

export function useCart(): UseCartReturn {
  const dispatch = useDispatch()

  const cart = useSelector<RootState, CartState>(state => state.cart)

  const [isLoadingCartItems, setIsLoadingCartItems] = useState(false)
  const [{ lines }, setLocalCart] = useState(cart)

  const { testsVariants } = useABtests()

  const checkoutUrl = (cart.checkoutUrl || '').replace(/origin\./, '')
  useEffect(() => {
    setLocalCart({ ...cart, lines: sortCustomAttributes(cart.lines) })
    setIsLoadingCartItems(false)
  }, [cart])

  const addToCart: AddToCart = async (payload, context = 'Cart') => {
    try {
      setIsLoadingCartItems(true)

      await dispatch(addToCartAction(payload, context))
      setIsLoadingCartItems(false)
    } catch (e) {
      setIsLoadingCartItems(false)
    }
  }

  const updateGiftMessage: UpdateGiftMessage = async message => {
    try {
      setIsLoadingCartItems(true)
      const payload = message
        ? {
            key: 'gift_message',
            value: message
          }
        : []
      await dispatch(updateCartAttributes(payload, 'Cart'))
      setIsLoadingCartItems(false)
    } catch (e) {
      setIsLoadingCartItems(false)
    }
  }

  const updateCartQuantity: UpdateCartQuantity = async (
    id,
    merchandiseId,
    quantity,
    context = 'Cart'
  ) => {
    if (isLoadingCartItems) return

    try {
      setIsLoadingCartItems(true)
      const { payload, newItems } = getCartUpdatePayload({
        id,
        merchandiseId,
        quantity,
        lines
      })
      setLocalCart({ ...cart, lines: newItems })
      await dispatch(updateCart(payload, context))
      setIsLoadingCartItems(false)
    } catch (e) {
      setIsLoadingCartItems(false)
    }
  }

  const navigateToCheckout = async ({
    triggerButtonReady
  }: SubmitButtonHandlers) => {
    const cartAttributes = [
      ...getAbTestCheckoutAttributes(testsVariants),
      ...cart.attributes
    ]

    if (cartAttributes.length > 0) {
      await dispatch(updateCartAttributes(cartAttributes, 'Cart'))
    }

    const location = (window as Window).location

    triggerButtonReady()

    location.href = checkoutUrl
  }

  return {
    cart,
    lines,
    isLoadingCartItems,
    addToCart,
    updateGiftMessage,
    updateCartQuantity,
    navigateToCheckout
  }
}
